<!-- 更新瀏覽器提醒視窗 -->
<template>
  <div
    class="modal-cont"
    v-if="visible"
  >
    <!-- <v-btn
      icon="close"
      class="close-modal"
      size="24"
      variant="text"
      @click="close"
    /> -->
    <h6 class="bold mb-6">{{ $t(Words.Dialog_BrowserUpgrade_Title) }}</h6>
    <div class="modal-content font-body-2">
      <img src="/website-qr-eee.png" style="float: right; margin: -5px 10px 0 20px" width="90" height="90">
      {{ $t(Words.Dialog_BrowserUpgrade_Message) }}
    </div>
    <div class="actions mt-6">
      <v-btn
        color="primary"
        flat
        size="36"
        class="font-weight-bold"
        :href="downloadUrl"
        target="_blank"
      >
        {{ $t(Words.Dialog_BrowserUpgrade_Action) }}
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts" setup>
const { english: enMode } = useViewMode()
const headers = useRequestHeaders()
const visible = ref(false)

const open = () => {
  visible.value = true
}
const close = () => {
  visible.value = false
}

defineExpose({
  open,
  close,
})

const isOldEdge = computed(() => {
  if (process.client) {
    // 新版的 Microsoft Edge 已經改變了使用者代理字串中 "Edge" 的關鍵字，將其改為 "Edg"。這是為了更清楚地表示瀏覽器現在是基於 Chromium 開發的版本，以區別於舊版的非 Chromium 開發版本。
    return /Edge/.test(navigator.userAgent) && !/Edg\//.test(navigator.userAgent)
  } else if (headers['user-agent']) {
    return /Edge/.test(headers['user-agent']) && !/Edg\//.test(headers['user-agent'])
  }
  return false
})

visible.value = isOldEdge.value

const downloadUrl = computed(() => {
  if (enMode.value) {
    return 'https://www.microsoft.com/en-us/edge/download'
  } else {
    return 'https://www.microsoft.com/zh-tw/edge/download'
  }
})

onMounted(() => {
  if (isOldEdge.value) {
    open()
  }
})

</script>

<style lang="stylus" scoped>

$padding = 24px
// :deep .modal-upgrade
//   top 52px
//   @media $mobile
//     left 0
//     right 0
//     margin 0
//     max-width 100%
//     width 100%
.modal-cont
  position absolute
  box-sizing border-box
  top 75px
  z-index 1000
  left 0
  right 0
  width 600px
  max-width 100%
  margin auto

  @media $mobile
    left 0
    right 0
    margin 0
    max-width 100%
    width 100%

  background $gray-lighten-3
  box-shadow 0px 2px 1px 0px rgba(0, 0, 0, 0.20)
  padding $padding
  +under(sm)
    padding $padding
  .modal-content
    // font-size 20px
    // line-height 32px
    // letter-spacing 0.25px
  
  .close-modal
    color $black-0-38
    position absolute
    right $padding
    top $padding
  .actions
    display flex
    gap $padding
    .v-btn
      flex 1 1 0
</style>