import { createAction } from '@/utils/api'

/**
 * 選單顯示(需要token判斷，僅限登入後使用)
 */
 export const loadVisibleMenu = createAction<
 // Response
 {
    showNews: boolean,
    showComments: boolean,
    showAnnouncement: boolean,
    showForms: boolean,
 }
>('GET', '/api/:locale/menu')