<template>
  <div class="chat-controller" v-if="!!currentMember">
    <v-btn
      class="float-button"
      :icon="isOpened ? 'last_page' : 'forum'"
      color="primary"
      @click="controlChatbot" />
    <LazyChatbot :type="deviceView['<xs'] ? 'full' : 'normal'" :on-back="() => toggleChatbot(false)" class="float-card" :class="botClass" />

    <!-- 登入提醒 -->
    <ModalLoginReminder ref="reminder" />
  </div>
</template>

<script setup lang="ts">
import { isOpened, toggleChatbot } from '@/lib/chatbotWindow'

import { useChatLog } from '~/composables/chatbot/useChatLog'
/** Register store on the top of components */
useChatLog()

const currentMember = useMember()

const deviceView = useDeviceView()
const botClass = computed(() => {
  return {
    normal: !deviceView['<xs'],
    full: deviceView['<xs'],
    open: isOpened.value,
  }
})

const reminder = ref()
function openReminder() {
  if (reminder?.value) {
    reminder?.value.open()
  }
}

function controlChatbot() {
  if (currentMember.value) {
    toggleChatbot()
  } else {
    openReminder()
  }
}

/** Close if logged out */
watch(currentMember, (member) => {
  if (!member) {
    toggleChatbot(false)
  }
})

watch(isOpened, (show) => {
  if (show) {
    document.querySelector('html')?.classList.add('no-scroll')
  } else {
    document.querySelector('html')?.classList.remove('no-scroll')
  }
})
</script>

<style lang="stylus" scoped>
.chat-controller
  position fixed
  right 16px
  bottom 40px
  z-index 100
  overscroll-behavior contain
  .float-button
    background linear-gradient(135deg, #27419F 0%, #A82262 100%)
  .float-card
    opacity 0
    transform scale(0)
    transform-origin right bottom
    transition width 200ms ease 0s, height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s
    pointer-events none
    // 一般版本
    &.normal
      position absolute
      bottom 0
      right calc(100% + 12px)
    // 全屏版本
    &.full
      position fixed
      top 0
      left 0
      &.open
        width 100%
        min-height 80px
        height 100%
    &.open
      opacity 1
      transform scale(1)
      transition width 200ms ease 0s, height 200ms ease 0s, transform 300ms cubic-bezier(0, 1.2, 1, 1) 0s, opacity 83ms ease-out 0s
      pointer-events all
</style>
