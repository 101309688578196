<template>
  <div class="page-layout">
    <Header />
    <div class="page-content">
      <slot />
    </div>
    <ClientOnly>
      <ChatbotWindow v-if="bindChat" />
    </ClientOnly>
    <Footer />
  </div>
</template>

<script setup>
const route = useRoute()
const getRouteBaseName = useRouteBaseName()

const baseRouteName = computed(() => {
  return getRouteBaseName(route)
})
const bindChat = computed(() => {
  if (baseRouteName.value === 'comments-id') return false
  return true
})

useSiteHead()

</script>

<!---

  設定頁面主題色的方法：

  useHead({
    bodyAttrs: {
      class: 'gloomy'
    }
  })

-->

<style lang="stylus">
@import '@/assets/styles/print.styl'

// 頁面主題
body.gloomy .page-layout
  background $gray-lighten-5
  .page-content
    border-bottom 1px solid $black-0-06

</style>