<!-- 登入提醒視窗 -->
<template>
  <v-dialog v-model="dialog" max-width="800">
    <div class="modal-cont">
      <component :is='deviceView.desktop ? "h5" : "h4"' class="bold text-center mt-6">{{ $t(Words.Dialog_AccessRestricted) }}</component>
      <div class="modal-content" :class="deviceView.desktop ? 'mt-12' : 'mt-6'">
        <component :is='deviceView.desktop ? "h6" : "div"' :class="{'font-body': !deviceView.desktop}">{{ $t(Words.Dialog_LoginToView) }}</component>
      </div>

      <div class="actions mt-12">
        <v-btn color="#F5F5F5" flat :size="deviceView.desktop ? 'x-large' : 'large'" class="font-weight-bold" @click="close">
          {{ $t(Words.Close) }}
        </v-btn>
        <v-btn color="primary" flat :size="deviceView.desktop ? 'x-large' : 'large'" class="font-weight-bold" :to="localePath({name: 'member-signin'})" @click="useLogin({ manually: true })">
          {{ $t(Words.AccountSignupForm_Login) }}
        </v-btn>
      </div>
    </div>
  </v-dialog>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
const deviceView = useDeviceView()

let dialog = ref(false)

const open = () => {
  dialog.value = true
}
const close = () => {
  dialog.value = false
}

defineExpose({
  open,
  close,
})

</script>

<style lang="stylus" scoped>
.modal-cont
  background #fff
  padding 48px
  +under(sm)
    padding 24px
  .modal-content
    font-size 20px
    line-height 32px
    letter-spacing 0.25px
  .actions
    display flex
    gap 24px
    .v-btn
      flex 1 1 0
</style>